export function AddLabelModal() {
    return <div className='add-label-modal'>
        <div className="add-label-btn-modal-container flex column">

            <button className="btn">Priority</button>
            <button className="btn">Text</button>
            <button className="btn">Date Range</button>

        </div>

    </div>

}